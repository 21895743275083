@import "../../../helpers/mixins";

.send-application-request {

  form {

    .application-request__field {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #151523;
      margin-bottom: 20px;

      &.has-error {

        input {
          border: 1px solid #D81C1C;
        }
      }
    }

    .form-check {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 20px;
      }

      .form-check-input {
        display: none;

        &:checked {
          background-size: cover;
          background-image: url("./images/icon-checked.svg");
          border: 0;

          & ~ {
            .form-check-text {
              border: 1px solid #5A2AC8;

              &:before {
                background-image: url("./images/icon-checked.svg");
              }
            }
          }
        }
      }

      .form-check-text {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        border: 1px solid #E0E2E7;
        border-radius: 4px;
        padding: 14px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #151523;

        &:before {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          background-image: url("./images/icon-not-checked.svg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 10px;
        }

        img {
          width: 18px;
          margin-right: 5px;
        }
      }
    }

    input {
      background: #FFFFFF;
      border: 1px solid #E0E2E7;
      border-radius: 4px;
      padding: 14px;
      outline: none;
      margin-top: 12px;
      color: #151523;

      &::placeholder {
        border: 1px solid #E0E2E7;
        color: #87898F;
      }

      &:focus {
        border: 1px solid #5A2AC8;
        color: #151523;
      }
    }

    .input-error {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #D81C1C;
      margin-top: 8px;
    }

    button {
      width: 100%;
      display: block;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #FFFFFF;
      background: #5A2AC8;
      border-radius: 4px;
      padding: 14px 0;
      border: 0;
      cursor: pointer;
      margin-bottom: 40px;
      @include transite();

      &.form__button-save_inactive {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
}
