@import "../../../helpers/mixins";

.not-found {


  .not-found__title {
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    color: #151523;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 0;

    @include r(1023) {

    }

    @include r(480) {

      font-size: 80px;
      line-height: 97px;
    }
  }

  .not-found__description {
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
    color: #151523;
    margin-bottom: 20px;
  }

  .not-found__images {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }

  .not-found__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #77787B;
    text-align: left;
  }

  .not-found__button {
    display: block;
    text-align: center;
    background: #5A2AC8;
    border-radius: 4px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 14px 0;
    margin-top: 110px;
    text-decoration: none;
    @include transite();

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
}
