@import "../../../helpers/mixins";

.application-sent {
  text-align: center;

  .application-sent__image {
    margin-bottom: 5px;
  }

  .application-sent__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
    color: #151523;
  }

  .application-sent__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #151523;
    margin-top: 0;
  }

  .application-sent__button {
    display: block;
    text-align: center;
    background: #5A2AC8;
    border-radius: 4px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 14px 0;
    margin-top: auto;
    text-decoration: none;
    @include transite();

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
}
